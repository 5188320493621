class Modal {
    constructor(selector) {
        this.selector = selector;


        this.modal = this.selector.querySelector('[data-element="modal"]');
        if(this.modal === null) {
            let modal_selector = this.selector.getAttribute('data-target');
            if(modal_selector !== null) {
                this.modal = document.querySelector(modal_selector);
            }
        }

        this._toggleModal = this._toggleModal.bind(this);
        this._closeModal = this._closeModal.bind(this);
        this._closeModalBody = this._closeModalBody.bind(this);

        if(this.modal !== null) {
            this.selector.addEventListener('click', this._toggleModal);
            let close = this.modal.querySelector('[data-element="close"]');
            if(close !== null) {
                close.addEventListener('click', this._closeModal);
            }
            document.addEventListener('click', this._closeModalBody);
        }


    }

    _toggleModal(event) {
        let modal_clicked = event.target.closest('.modal');
        if(!modal_clicked) {

            if (this.modal.classList.contains('modal--shown')) {
                this.modal.classList.remove('modal--shown');
                document.querySelector('.modal__overlay').classList.remove('overlay--shown');
            } else {
                this.modal.classList.add('modal--shown');
                document.querySelector('.modal__overlay').classList.add('overlay--shown');
            }

        }

    }

    _closeModal(event) {
        event.preventDefault();
        this.modal.classList.remove('modal--shown');
        document.querySelector('.modal__overlay').classList.remove('overlay--shown');

    }

    _closeModalBody(event) {
         let clicked = event.target.closest('.modal');
         let clk2 = event.target.closest('.js-modal');
         if(clicked === null && clk2 === null) {

             this.modal.classList.remove('modal--shown');
             document.querySelector('.modal__overlay').classList.remove('overlay--shown');
         }


    }
}

export default Modal;